body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevents horizontal scroll due to transformations */
  perspective: 1000px; /* Creates a 3D effect for the container */
}

.top-vpn-container {
  background-color: #2e399c81;
  border: 5px solid #2d4bd381;
  width: 85%;
  padding: 15px 15px;
}

.logo-banner {
  width: 110%;
}

.top-vpn-images {
  max-width: 32px;
  max-height: 32px;
  background: none;
  box-shadow: 1px 1px 1px 1px black;
}

.vpn-image {
  max-width: 32px;
  max-height: 32px;
  background: none;
  box-shadow: 1px 1px 1px 1px black;
}

.logo-banner {
  margin-top: -29px;
}

.top-vpn-container:hover {
  transform: translateX(-5px);
}

.right-align {
  display: inline-block;
  text-align: right;
  width: 50%;
}

.left-align {
  display: inline-block;
  text-align: left;
  width: 50%;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top-container,
.tierlist-container,
.info-container,
.titles-container,
.tier-list,
.updates-container {
  transform: perspective(1000px) translateZ(0); /* Initialize the perspective */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform-style: preserve-3d; /* Ensures children maintain their 3D position */
  margin-bottom: 20px;
}

.top-container {
  width: 90%;
  border: 2px solid #333;
  background-color: #2e399c81;
  padding: 20px;
  margin-top: 30px;
  border-radius: 16px;
  box-shadow: 0px 8px 16px rgba(8, 6, 179, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.top-container:hover {
  transform: translateY(-3px);
  box-shadow: 0px 12px 22px rgba(8, 6, 179, 0.4);
}

.top-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.top-container:hover::before {
  opacity: 1;
}

.top-container:active {
  box-shadow: 0px 8px 16px rgba(8, 6, 179, 0.3);
  transform: translateY(0px);
}

.tierlist-container {
  border: 2px solid #333;
  background-color: #2e399c81;
  max-width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(8, 6, 179, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tierlist-container:hover {
  transform: translateY(-3px);
  box-shadow: 0px 16px 32px rgba(8, 6, 179, 0.4);
}

.tierlist-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1));
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tierlist-container:hover::before {
  opacity: 1;
}

.tierlist-container:active {
  box-shadow: 0px 8px 16px rgba(8, 6, 179, 0.3);
  transform: translateY(0px);
}

.info-container {
  width: 55%;
  text-align: center;
  border: 2px solid #333;
  background-color: #2e399c81;
  padding: 10px;
  margin-top: 30px;
  border-radius: 8px;
}

.info-container:hover {
  box-shadow: 0px 4px 8px rgba(8, 6, 179, 0.3);
}

.info-note {
  font-style: italic;
  color: #777;
}

.titles-container {
  width: 90%;
  /* background-color: #2e399c81; */
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.titles-container h3 {
  font-size: 1.5em;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  margin: auto;
}

.titles-container p {
  margin: 0;
  font-size: 1.1em;
  color: #ccc;
  line-height: 1.5;
  text-align: center;
}

.titles-container p a {
  color: #66BFFF;
  text-decoration: none;
  transition: color 0.3s;
}

.tier-list {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #181b1f;;
  background-size: 400% 400%;
  color: #fff;
  animation: background-animation 15s linear infinite;
}

.intro-text {
  font-size: x-large;
  width: 50%;
  margin: 20px;
  text-align: center;
}

.tiers-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border: 1px solid #333;
  background-color: #0f1655;
  box-shadow: 0px 4px 8px rgba(14, 12, 133, 0.3);
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  height: auto;
  width: 200px;
}

.tier:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 16px rgba(8, 6, 179, 0.3);
}

.tier h2 {
  margin: 0;
  font-size: 24px;
  color: #fff;
}

.items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vpn-item {
  padding: 10px;
  border: 1px solid #0e1da0;
  background-color: #2f0caa;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  transition: background-color 0.3s;
  height: 25px;
}

.vpn-item:hover {
  background-color: #104786;
}

.updates-container {
  width: 80%;
  background-color: #2e399c81;
  border: 2px solid #333;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(8, 6, 179, 0.3);
}

.updates-container h3 {
  font-size: 1.3em;
  color: #fff;
  margin-bottom: 10px;
}

.updates-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.updates-container li {
  font-size: 1.1em;
  margin-bottom: 8px;
  color: #ccc;
}

.updates-container ul ul {
  list-style-type: circle;
  margin-left: 20px;
}

@keyframes background-animation {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
