body {
  background-color: #181b1f;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.privacy-policy-title {
  font-size: 30px;
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
}

.privacy-policy-title-container {
  border-radius: 8px;
  border: 2px solid #333;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 75%;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(8, 6, 179, 0.3);
  background-color: #2e399c81;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #333;
  border-radius: 8px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 4px 8px rgba(8, 6, 179, 0.3);
  max-width: 75%;
  padding: 20px;
  background-color: #2e399c81;
  color: #fff;
}

.privacy-policy h1,
.privacy-policy h2,
.privacy-policy h3 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 75%;
  text-align: center;
  color: #fff;
}

.privacy-policy p {
  max-width: 75%;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
  text-align: center;
  color: #ccc;
}

/* Additional Styling for Lists and Links */
.privacy-policy ul {
  list-style-type: disc;
  padding-left: 40px;
  max-width: 75%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ccc;
}

.privacy-policy ul li {
  font-size: 22px;
  margin-bottom: 8px;
}

.privacy-policy a {
  color: #66BFFF;
  text-decoration: none;
  transition: color 0.3s;
}

.privacy-policy a:hover {
  color: #99ccff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy-title-container,
  .privacy-policy {
      max-width: 90%;
  }
  
  .privacy-policy h1,
  .privacy-policy h2,
  .privacy-policy h3,
  .privacy-policy p,
  .privacy-policy ul li {
      font-size: 18px;
  }
}
